<template>
  <!-- 行业资讯 -->
  <div id="JobChance">
    <!-- 轮播图 -->
    <Banner :swiperList="swiperList" :mobileSwiperList="mobileSwiperList" />

    <!-- 行业资讯 -->
    <div id="Case" class="container-fuild">
      <div class="container Case-container">
        <div class="Case_content_weapper">
          <!-- 面包屑 -->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <div class="case_breadcrumb">当前位置：</div>
            <el-breadcrumb-item
              ><a @click="scrollToNav(0, '/home')">首页</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              ><a @click="scrollToNav(3, '/newsinformation')"
                >新闻媒体</a
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item>行业资讯</el-breadcrumb-item>
          </el-breadcrumb>

          <!-- 富文本解析 -->

          <div class="Case_content" v-html="navList"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import Banner from "../components/banner"; //轮播图
import { getbannerList } from "../api/home";

export default {
  name: "JobChance",
  components: {
    Banner,
  },
  data() {
    return {
      swiperList: [],
      mobileSwiperList: [],
      navList: "",
    };
  },
  mounted() {
    this.navList = this.$route.query.content;
    this.bannerList();

    var wow = new WOW();
    wow.init();
  },
  methods: {
    scrollToNav(number, path) {
      this.$parent.$parent.$refs.header.navIndex = number;
      window.scrollTo(0, 0);
      sessionStorage.setItem("navIndex", number);

      this.$router.push({
        path,
      });
    },
    bannerList() {
      getbannerList({
        display: 2,
        adaptation: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.swiperList = res.rows;
        }
      });
      getbannerList({
        display: 2,
        adaptation: 2,
      }).then((res) => {
        if (res.code == 0) {
          this.mobileSwiperList = res.rows;
        }
      });
    },
  },
};
</script>
<style scoped>
/* 企业案例 */
#Case {
  background-color: #fff;
  padding-bottom: 70px;
}
.Case_content_weapper {
  margin-top: 20px;
}
.case_breadcrumb {
  float: left;
  border-left: 2px solid #2a80ef;
  padding-left: 10px;
}

.Case_content {
  width: 100%;
  margin-top: 20px;

}
.Case_content img {
  width: 100%;
}
</style>

